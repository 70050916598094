import { Links, Scripts, LiveReload, AdminPanel, LosseLink } from '@ubo/losse-sjedel'
import LogoBackground from '~/shapes/Logo'
import Logo from '../elements/Logo'

export default function PageNotFound() {
  return (
    <html lang="nl" className="h-full w-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body className="h-full w-full">
        <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 relative overflow-hidden">
          <div className="max-w-max mx-auto section relative z-10">
            <Logo className="mx-auto text-white mb-10" />
            <main className="sm:flex">
              <p className="text-4xl font-extrabold text-ftf-punch sm:text-5xl">404</p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-extrabold text-white tracking-tight sm:text-5xl">Pagina niet gevonden</h1>
                  <p className="mt-1 text-base text-white/75">Controleer de URL in de adresbalk en probeer het opnieuw.</p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <LosseLink to="/" className="btn--punch">
                    Ga naar home
                  </LosseLink>
                  <LosseLink to="/contact/" className="btn--white--outline">
                    Contact
                  </LosseLink>
                </div>
              </div>
            </main>
          </div>
          {/* 0,607142857142857 aspect ratio */}
          <LogoBackground className="z-0 opacity-25 absolute left-1/2 -translate-1/2 top-1/2 -translate-y-1/2 w-[334px] h-[550px] md:w-[510px] md:h-[840px]" />
        </div>
        <Scripts />
        <LiveReload />
        <AdminPanel />
      </body>
    </html>
  )
}
